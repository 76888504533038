<template>
    <nav>
        <div class="nav"> 
            <!-- new version -->
            <div class="nav-item">
                <router-link :to="backTo" class="nav-link">
                    <img src="@/assets/img/auth/back.png" alt="back" />
                </router-link>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    props: {
        backTo: {
            type: String,
            default: '/'
        }
    }
}

</script>


<style scoped>
img {
    width: 20px;
    height: 20px;
}
/* 모든 것을 왼쪽 정렬한다. */
.nav{
    display: flex;
    justify-content: left;
    width: 100%;
    padding: 20px
}

</style>